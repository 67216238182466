import { ensureListing } from "../../util/data";
import defaultConfig from '../../config/configDefault';
import { types as sdkTypes } from '../../util/sdkLoader';

const { Money } = sdkTypes;

/**
 * This function will calculate the total price of the cart.
 *
 * @param {array} items
 * @returns {Money} total
 */
export const calcCartTotal = items => {
  if (items.length === 0) return new Money(0, defaultConfig.currency);
  const { currency } = items[0].price;
  const totalAmount = items.reduce((acc, elem) => acc + elem.price.amount * elem.quantity, 0);
  return new Money(totalAmount, currency);
}

/**
 * This function will ensure that the cart item listing is valid
 * 
 * @param {array} cartDataRaw
 * @param {array} listings
 * @param {array} storeListings
 * @returns {array} cartData
 */
export const ensureCartItemListing = (cartDataRaw = [], listings = [], storeListings = []) => {
  return cartDataRaw.map(cartItem => {
    const listing = listings.find(l => l.id.uuid === cartItem.listingId);
    const storeListing = storeListings.find(l => l.author.id.uuid === cartItem.authorId);
    if (!listing || !storeListing) {
      return {
        ...cartItem,
        data: ensureListing(listing),
        isNotAvailable: true,
      };
    }
    return { ...cartItem, data: listing };
  });
};

/**
 * This function will generate the quantity field key
 * 
 * @param {string} listingId
 * @param {string} deliveryMethod
 * @param {string} seperator
 * @returns {string} quantityFieldKey
 */
export const generateQuantityFieldKey = (listingId, deliveryMethod, seperator = '#') =>
  `${listingId}${seperator}${deliveryMethod}${seperator}quantity`;

/**
 * This function will parse the quantity field key
 * 
 * @param {string} quantityFieldKey
 * @param {string} seperator
 * 
 * @returns {object} { listingId, deliveryMethod }
 */
export const parseQuantityFieldKey = (quantityFieldKey = '', seperator = '#') => {
  const [listingId, deliveryMethod, _] = quantityFieldKey.split(seperator);
  return { listingId, deliveryMethod };
};