import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { ValidationError } from '../../components';

import StepperInput from './StepperInput'; // You need to create or import a StepperInput component

import css from './FieldStepper.module.css';

const FieldStepperComponent = props => {
  const {
    rootClassName,
    className,
    id,
    label,
    input,
    meta,
    onChange,
    maxNumber,
    ...rest
  } = props;

  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const { valid, invalid, touched, error } = meta;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = touched && invalid && error;

  const handleChange = (value) => {
    const cappedValue = maxNumber ? Math.min(value, maxNumber) : value;
    input.onChange(cappedValue);
    if (onChange) {
      onChange(cappedValue);
    }
  };

  const stepperProps = {
    id,
    value:
      input.value
        ? Number(input.value)
        : 1,
    onChange: handleChange,
    min: 1,
    max: maxNumber, // Set the max attribute for the numeric input
    ...rest,
  };

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <StepperInput {...stepperProps} />
      <ValidationError fieldMeta={meta} />
    </div>
  );
};

FieldStepperComponent.defaultProps = {
  rootClassName: null,
  className: null,
  id: null,
  label: null,
  maxNumber: null, // Default maxNumber to null
};

const { string, object, number, func } = PropTypes;

FieldStepperComponent.propTypes = {
  rootClassName: string,
  className: string,
  id: string,
  label: string,
  maxNumber: number, // Prop for maximum value

  // Generated by final-form's Field component
  input: object.isRequired,
  meta: object.isRequired,
};

const FieldStepper = props => {
  return <Field component={FieldStepperComponent} {...props} />;
};

export default FieldStepper;
