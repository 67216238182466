import React, { useEffect, useRef } from 'react';
import PropTypes, { number } from 'prop-types';
import classNames from 'classnames';
import css from './IconCart.module.css';

const MAX_DISPLAYED_QUANTITY = 99;
const IconCart = props => {
  const {
    rootClassName,
    rootIconClassName,
    iconClassName,
    className,
    quantity,
    totalCartItemQuantity,
    rootQuantityClassName,
    quantityClassName,
    rootContainerClassName,
    containerClassName,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const containerClasses = classNames(rootContainerClassName || css.container, containerClassName);
  const iconClasses = classNames(rootIconClassName || css.icon, iconClassName);
  const quantityClasses = classNames(rootQuantityClassName || css.quantity, quantityClassName);
  const formattedQuantity =
    quantity > MAX_DISPLAYED_QUANTITY ? `${MAX_DISPLAYED_QUANTITY}+` : quantity;
  const quantityRef = useRef();
  const isFirstRendered = useRef(true);

  useEffect(() => {
    if (quantityRef.current) {
      if (isFirstRendered.current) {
        isFirstRendered.current = false;
      } else {
        quantityRef.current.animate(
          [
            { transform: 'translate(50%, -25%) scale(1.2)' },
            { transform: 'translate(50%, -25%) scale(1)' },
          ],
          {
            duration: 200,
            iterations: 1,
          }
        );
      }
    }
  }, [totalCartItemQuantity]);

  return (
    <div className={classes}>
      <div className={containerClasses}>
        <span className={`${iconClasses} icon material-symbols-outlined`}>shopping_cart</span>
        {quantity > 0 && (
          <span ref={quantityRef} className={quantityClasses}>
            {formattedQuantity}
          </span>
        )}
      </div>
    </div>
  );
};

IconCart.defaultProps = {
  rootClassName: null,
  className: null,
  quantity: 0,
};

const { string } = PropTypes;

IconCart.propTypes = {
  rootClassName: string,
  className: string,
  quantity: number,
};

export default React.memo(IconCart);
