// StepperInput.jsx
import React from 'react';
import PropTypes from 'prop-types';
import css from './FieldStepper.module.css';

const StepperInput = ({ id, value, onChange, min, max, step }) => {

  const handleIncrement = e => {
    e.preventDefault();
    onChange(value + step);
  };

  const handleDecrement = e => {
    e.preventDefault();
    onChange(value - step);
  };

  return (
    <div className={css.stepperInputWrapper}>
      <button onClick={handleDecrement} disabled={value <= min}>
        -
      </button>
      <input
        type="number"
        id={id}
        value={value}
        min={min}
        max={max}
        step={step}
        readOnly={true}
        onChange={e => onChange(Number(e.target.value))}
      />
      <button onClick={handleIncrement} disabled={value >= max}>
        +
      </button>
    </div>
  );
};

StepperInput.defaultProps = {
  min: 0,
  max: Infinity,
  step: 1,
};

const { string, number, func } = PropTypes;

StepperInput.propTypes = {
  id: string.isRequired,
  value: number.isRequired,
  onChange: func.isRequired,
  min: number,
  max: number,
  step: number,
};

export default StepperInput;
