import { groupBy, pick, values, get } from 'lodash';
import { storableError } from '../../util/errors';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { denormalisedResponseEntities } from '../../util/data';
import { ERROR_CODE_CART_IS_FULL, ERROR_CODE_REACHED_MAXIMUM_QUANTITY } from '../../util/types';
import { currentUserShowSuccess } from '../../ducks/user.duck';
import { ensureCartItemListing } from './CartPage.helpers';

const MAX_ITEMS = 100;

// ================ Action types ================ //

export const FETCH_CART_DATA_REQUEST = 'app/CartPage/FETCH_CART_DATA_REQUEST';
export const FETCH_CART_DATA_SUCCESS = 'app/CartPage/FETCH_CART_DATA_SUCCESS';
export const FETCH_CART_DATA_ERROR = 'app/CartPage/FETCH_CART_DATA_ERROR';

export const ADD_ITEM_TO_CART_DATA_REQUEST = 'app/CartPage/ADD_ITEM_TO_CART_DATA_REQUEST';
export const ADD_ITEM_TO_CART_DATA_SUCCESS = 'app/CartPage/ADD_ITEM_TO_CART_DATA_SUCCESS';
export const ADD_ITEM_TO_CART_DATA_ERROR = 'app/CartPage/ADD_ITEM_TO_CART_DATA_ERROR';

export const REMOVE_ITEM_TO_CART_DATA_REQUEST = 'app/CartPage/REMOVE_ITEM_TO_CART_DATA_REQUEST';
export const REMOVE_ITEM_TO_CART_DATA_SUCCESS = 'app/CartPage/REMOVE_ITEM_TO_CART_DATA_SUCCESS';
export const REMOVE_ITEM_TO_CART_DATA_ERROR = 'app/CartPage/REMOVE_ITEM_TO_CART_DATA_ERROR';

export const UPDATE_ITEM_QUANTITY_REQUEST = 'app/CartPage/UPDATE_ITEM_QUANTITY_REQUEST';
export const UPDATE_ITEM_QUANTITY_SUCCESS = 'app/CartPage/UPDATE_ITEM_QUANTITY_SUCCESS';
export const UPDATE_ITEM_QUANTITY_ERROR = 'app/CartPage/UPDATE_ITEM_QUANTITY_ERROR';

export const UPDATE_CART_DATA_AFTER_CHECKOUT_REQUEST =
  'app/CartPage/UPDATE_CART_DATA_AFTER_CHECKOUT_REQUEST';
export const UPDATE_CART_DATA_AFTER_CHECKOUT_SUCCESS =
  'app/CartPage/UPDATE_CART_DATA_AFTER_CHECKOUT_SUCCESS';
export const UPDATE_CART_DATA_AFTER_CHECKOUT_ERROR =
  'app/CartPage/UPDATE_CART_DATA_AFTER_CHECKOUT_ERROR';

// ================ Reducer ================ //

const initialState = {
  cartData: [],
  storeListings: [],
  fetchCartDataInProgress: false,
  fetchCartDataError: null,
  addItemInProgress: false,
  addItemError: null,
  removeItemKey: null,
  removeItemInProgress: false,
  removeItemError: null,
  updateItemQuantityInProgress: false,
  updateItemQuantityError: null,
  updateCartDataAfterCheckoutError: false,
};

export default function cartPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_CART_DATA_REQUEST:
      return {
        ...state,
        fetchCartDataInProgress: true,
        fetchCartDataError: null,
      };
    case FETCH_CART_DATA_SUCCESS:
      return {
        ...state,
        fetchCartDataInProgress: false,
        cartData: payload.listingData,
        storeListings: payload.storeListings,
      };
    case FETCH_CART_DATA_ERROR:
      console.error(payload); // eslint-disable-line
      return {
        ...state,
        fetchCartDataInProgress: false,
        fetchCartDataError: payload,
      };

    case ADD_ITEM_TO_CART_DATA_REQUEST:
      return { ...state, addItemInProgress: true, addItemError: null };
    case ADD_ITEM_TO_CART_DATA_SUCCESS: {
      return {
        ...state,
        addItemInProgress: false,
      };
    }
    case ADD_ITEM_TO_CART_DATA_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, addItemInProgress: false, addItemError: payload };

    case REMOVE_ITEM_TO_CART_DATA_REQUEST:
      return {
        ...state,
        removeItemKey: payload,
        removeItemInProgress: true,
        removeItemError: null,
      };
    case REMOVE_ITEM_TO_CART_DATA_SUCCESS:
      return {
        ...state,
        removeItemKey: null,
        removeItemInProgress: false,
        cartData: payload,
      };
    case REMOVE_ITEM_TO_CART_DATA_ERROR:
      console.error(payload); // eslint-disable-line
      return {
        ...state,
        removeItemKey: null,
        removeItemInProgress: false,
        removeItemError: payload,
      };

    case UPDATE_ITEM_QUANTITY_REQUEST:
      return {
        ...state,
        updateItemQuantityInProgress: true,
        updateItemQuantityError: null,
      };
    case UPDATE_ITEM_QUANTITY_SUCCESS: {
      return {
        ...state,
        updateItemQuantityInProgress: false,
        cartData: payload,
      };
    }
    case UPDATE_ITEM_QUANTITY_ERROR:
      console.error(payload); // eslint-disable-line
      return {
        ...state,
        updateItemQuantityInProgress: false,
        updateItemQuantityError: payload,
      };

    case UPDATE_CART_DATA_AFTER_CHECKOUT_REQUEST:
      return {
        ...state,
        updateCartDataAfterCheckoutError: null,
      };
    case UPDATE_CART_DATA_AFTER_CHECKOUT_SUCCESS: {
      return {
        ...state,
        cartData: payload,
      };
    }
    case UPDATE_CART_DATA_AFTER_CHECKOUT_ERROR:
      console.error(payload); // eslint-disable-line
      return {
        ...state,
        updateCartDataAfterCheckoutError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchCartDataRequest = () => ({ type: FETCH_CART_DATA_REQUEST });
const fetchCartDataSuccess = payload => ({
  type: FETCH_CART_DATA_SUCCESS,
  payload,
});
const fetchCartDataError = e => ({
  type: FETCH_CART_DATA_ERROR,
  error: true,
  payload: e,
});

const addItemToCartDataRequest = () => ({
  type: ADD_ITEM_TO_CART_DATA_REQUEST,
});
const addItemToCartDataSuccess = () => ({
  type: ADD_ITEM_TO_CART_DATA_SUCCESS,
});
const addItemToCartDataError = e => ({
  type: ADD_ITEM_TO_CART_DATA_ERROR,
  error: true,
  payload: e,
});

const removeItemToCartDataRequest = payload => ({
  type: REMOVE_ITEM_TO_CART_DATA_REQUEST,
  payload
});
const removeItemToCartDataSuccess = payload => ({
  type: REMOVE_ITEM_TO_CART_DATA_SUCCESS,
  payload,
});
const removeItemToCartDataError = e => ({
  type: REMOVE_ITEM_TO_CART_DATA_ERROR,
  error: true,
  payload: e,
});

const updateItemQuantityRequest = () => ({
  type: UPDATE_ITEM_QUANTITY_REQUEST,
});
const updateItemQuantitySuccess = payload => ({
  type: UPDATE_ITEM_QUANTITY_SUCCESS,
  payload,
});
const updateItemQuantityError = e => ({
  type: UPDATE_ITEM_QUANTITY_ERROR,
  error: true,
  payload: e,
});

const updateCartDataAfterCheckoutRequest = () => ({
  type: UPDATE_CART_DATA_AFTER_CHECKOUT_REQUEST,
});
const updateCartDataAfterCheckoutSuccess = payload => ({
  type: UPDATE_CART_DATA_AFTER_CHECKOUT_SUCCESS,
  payload,
});
const updateCartDataAfterCheckoutError = e => ({
  type: UPDATE_CART_DATA_AFTER_CHECKOUT_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const addItemToCart = params => async (
  dispatch,
  getState,
  sdk
) => {
  dispatch(addItemToCartDataRequest());

  const { currentUser } = getState().user;

  const {
    quantityAvailable,
    ...rest
  } = params;

  const currentCartData = get(currentUser, 'attributes.profile.privateData.cartData', []);

  const groupedByListingIdAndDeliveryMethod = groupBy(
    [...currentCartData, { ...rest, checked: true }],
    item => JSON.stringify(pick(item, ['listingId', 'deliveryMethod']))
  );

  const newCartData = values(groupedByListingIdAndDeliveryMethod).map(group => {
    const item = group[0];
    const quantity = group.reduce((total, elem) => total + elem.quantity, 0);

    return {
      ...item,
      quantity,
    };
  });

  const newCartItemCount = [...new Set(newCartData.map(item => item.listingId))].length;

  if (newCartItemCount > MAX_ITEMS) {
    const e = new Error(ERROR_CODE_CART_IS_FULL);
    dispatch(addItemToCartDataError(storableError(e)));
    throw e;
  }

  const totalQuantityOfItem = newCartData.reduce((total, elem) => {
    if (elem.listingId === rest.listingId) {
      return total + elem.quantity;
    }

    return total;
  }, 0);

  if (quantityAvailable < totalQuantityOfItem) {
    const e = new Error(ERROR_CODE_REACHED_MAXIMUM_QUANTITY);
    dispatch(addItemToCartDataError(storableError(e)));
    throw e;
  }

  return sdk.currentUser
    .updateProfile(
      {
        privateData: { cartData: newCartData },
      },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then(response => {
      dispatch(addItemToCartDataSuccess());
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }
      const currentUser = entities[0];

      // Update current user in state.user.currentUser through user.duck.js
      dispatch(currentUserShowSuccess(currentUser));

      return true;
    })
    .catch(e => {
      dispatch(addItemToCartDataError(storableError(e)));
      throw e;
    });
};

export const removeItemFromCart = ({ listingId, deliveryMethod }) => (dispatch, getState, sdk) => {
  const { cartData, updateItemQuantityInProgress, removeItemInProgress } = getState().CartPage;
  if (updateItemQuantityInProgress || removeItemInProgress) {
    return Promise.reject(new Error('UpdateItemQuantity or RemoveItem already in progress'));
  }
  const removeItemKey = `${listingId}${deliveryMethod}`;
  dispatch(removeItemToCartDataRequest(removeItemKey));

  const newCartDataSaveOnRedux = cartData.filter(
    item => item.listingId !== listingId || item.deliveryMethod !== deliveryMethod
  );

  const newCartDataSaveOnUser = newCartDataSaveOnRedux.map(item => {
    const { data, isNotAvailable, ...dataToSave } = item;
    return dataToSave;
  });

  return sdk.currentUser
    .updateProfile(
      { privateData: { cartData: newCartDataSaveOnUser } },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }
      const currentUser = entities[0];

      // Update current user in state.user.currentUser through user.duck.js
      dispatch(currentUserShowSuccess(currentUser));

      return true;
    })
    .then(() => dispatch(removeItemToCartDataSuccess(newCartDataSaveOnRedux)))
    .catch(e => {
      console.error(e);
      dispatch(removeItemToCartDataError(storableError(e)));
    });
};

export const updateItemQuantity = ({ listingId, newQuantity, deliveryMethod }) => (
  dispatch,
  getState,
  sdk
) => {
  const { cartData, updateItemQuantityInProgress, removeItemInProgress } = getState().CartPage;
  if (updateItemQuantityInProgress || removeItemInProgress) {
    return Promise.reject(new Error('UpdateItemQuantity or RemoveItem already in progress'));
  }

  dispatch(updateItemQuantityRequest());

  const newCartDataSaveOnRedux = cartData.map(item => {
    if (item.listingId === listingId && item.deliveryMethod === deliveryMethod) {
      return {
        ...item,
        quantity: newQuantity,
      };
    }

    return item;
  });

  const newCartDataForSaveOnUser = cartData.map(item => {
    const { data, isNotAvailable, ...dataToSave } = item;
    if (item.listingId === listingId && item.deliveryMethod === deliveryMethod) {
      return {
        ...dataToSave,
        quantity: newQuantity
      }
    }
    
    return dataToSave;
  });

  return sdk.currentUser
    .updateProfile({ privateData: { cartData: newCartDataForSaveOnUser } })
    .then(() => dispatch(updateItemQuantitySuccess(newCartDataSaveOnRedux)))
    .catch(e => {
      console.error(e);
      dispatch(updateItemQuantityError(storableError(e)));
    });
};

export const updateCartDataAfterCheckout = (cartItems, deliveryMethod) => (dispatch, getState, sdk) => {
  dispatch(updateCartDataAfterCheckoutRequest());

  const { cartData } = getState().CartPage;

  const listingIdsShouldBeRemoved = cartItems.map(item => item.listingId);

  const newCartDataSaveOnRedux = cartData.filter(
    item =>
      item.deliveryMethod !== deliveryMethod || !listingIdsShouldBeRemoved.includes(item.listingId)
  );

  const newCartDataSaveOnUser = newCartDataSaveOnRedux.map(item => {
    const { data, isNotAvailable, ...dataToSave } = item;
    return dataToSave;
  });

  return sdk.currentUser
    .updateProfile(
      { privateData: { cartData: newCartDataSaveOnUser } },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then(response => {
      dispatch(updateCartDataAfterCheckoutSuccess(newCartDataSaveOnRedux));
      
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }
      const currentUser = entities[0];

      // Update current user in state.user.currentUser through user.duck.js
      dispatch(currentUserShowSuccess(currentUser));
    })
    .catch(e => {
      console.error(e);
      dispatch(updateCartDataAfterCheckoutError(storableError(e)));
    });
};

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
  dispatch(fetchCartDataRequest());

  let cartDataRaw;
  let listingData;
  let storeListings;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  return sdk.currentUser
    .show()
    .then(response => {
      const currentUser = denormalisedResponseEntities(response)[0];
      const { cartData = [] } = get(currentUser, 'attributes.profile.privateData', {});
      cartDataRaw = cartData;
      const cartListingIds = [...new Set(cartData.map(item => item.listingId))];
      return sdk.listings.query({
        ids: cartListingIds,
        include: ['author', 'images', 'currentStock'],
        'fields.listing': ['title', 'state', 'price', 'publicData'],
        'fields.image': [
          'variants.square-small',
          'variants.square-small2x',
          `variants.${variantPrefix}`,
        ],
        ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
        'limit.images': 1,
      });
    })
    .then(response => {
      listingData = denormalisedResponseEntities(response);

      const storeListingIds = [
        ...new Set(listingData.map(item => item.author.attributes.profile.publicData.userListingId)),
      ];

      return sdk.listings.query({
        ids: storeListingIds,
        include: ['author', 'author.profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
        'limit.images': 1,
      });
    }).then(response => {
      storeListings = denormalisedResponseEntities(response);

      const cartItemListing = ensureCartItemListing(cartDataRaw, listingData, storeListings);
      dispatch(fetchCartDataSuccess({ listingData: cartItemListing, storeListings }));
    })
    .catch(e => dispatch(fetchCartDataError(storableError(e))));
};
